.Background {
  background-color: #eaeaea;
  min-height: 100vh;
  height: auto;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.HomeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.Icon1 {
  height: auto;
  width: auto;
  color: black;
  margin-left: 1vw;
  margin-right: 1vw;
}
.Icon2 {
  height: auto;
  width: auto;
  color: black;
  margin-left: 1vw;
  margin-right: 1vw;
  font-size: calc(10px + 4vw);
}

.IntroText {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: calc(10px + 1vw);
  font-family: "Roboto Mono", monospace;
}

.MediaIcons {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  font-size: calc(10px + 3vw);
}

.Name {
  font-size: calc(20px + 3vw);
  font-style: bold;
  font-family: "Roboto Mono", monospace;
}

.Portrait {
  max-height: 80vh;
  max-width: 80vw;
  height: auto;
  width: auto;
}

.ResumeButton {
  max-height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2vh;
}
